import { useEffect, useState } from 'react';
import { LocalStorageKeyEnum } from '../localStorage/constants';
import { EmployeeBiographical, EmployeePosition, Face } from '../types/types';
import { Cookies } from 'react-cookie';
import { sortFacesAlphabetically } from '../util/functions';
const cookies = new Cookies();

const useGetFaces = () => {
  const [loading, setLoading] = useState(false);
  const [usingCache, setUsingCache] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [bioData, setBioData] = useState<EmployeeBiographical[] | null>(null);
  const [posData, setPosData] = useState<EmployeePosition[] | null>(null);
  const [compiledData, setCompiledData] = useState<any>(null);
  const authToken = cookies.get('token');

  useEffect(() => {
    if (authToken === undefined) return;
    const cache = localStorage.getItem(LocalStorageKeyEnum.FACES);
    if (!cache) {
      setUsingCache(false);
    }
    setLoading(true);
    let infoStatusCode: number;
    fetch(`${process.env.REACT_APP_API_BASE}/staff/info`, {
      method: 'GET',
      headers: {
        token: authToken,
      },
    })
      .then((result) => {
        infoStatusCode = result.status;
        return result.json();
      })
      .then((result) => {
        if (infoStatusCode === 200) {
          setBioData(result);
        } else {
          setError(result.message);
        }
      })
      .catch((error) => console.log('error', error));

    let positionStatusCode: number;
    fetch(`${process.env.REACT_APP_API_BASE}/staff/positions`, {
      method: 'GET',
      headers: {
        token: authToken,
      },
    })
      .then((result) => {
        positionStatusCode = result.status;
        return result.json();
      })
      .then((result) => {
        if (positionStatusCode === 200) {
          setPosData(result);
        } else {
          setError(result.message);
        }
      })
      .catch((error) => console.log('error', error));
  }, [authToken]);

  useEffect(() => {
    if (authToken === undefined) return;
    if (!!bioData && !!posData) {
      setCompiledData(
        bioData.map((item: EmployeeBiographical) => {
          const position = posData.find(
            (posItem) => posItem.EmployeeNumber === item.EmployeeNumber
          );
          if (!position) return undefined;
          return {
            id: item.EmployeeId,
            name: item.PreferredName || item.FirstName,
            surname: item.LastName,
            cellNumber: item.CellNumber,
            email: item.Email,
            position: position?.OrganizationPosition,
            reportsTo: position.DirectlyReportsEmployee,
            location: position.OrganizationRegion,
            department: position.OrganizationGroupDescription,
          };
        })
      );
    }
  }, [bioData, posData]);

  useEffect(() => {
    if (authToken === undefined) return;
    if (!!compiledData) {
      const filtered = compiledData
        .filter((item: Face | undefined) => typeof item !== 'undefined')
        .sort((a: Face, b: Face) => sortFacesAlphabetically(a, b));
      const unique = [...new Set(filtered)];
      localStorage.setItem(LocalStorageKeyEnum.FACES, JSON.stringify(unique));
      setUsingCache(false);
      setLoading(false);
    }
  }, [compiledData]);

  return { loading, error, usingCache };
};

export default useGetFaces;
