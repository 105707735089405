/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  faBriefcase,
  faBuildingUser,
  faEnvelope,
  faLocationDot,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Image, Link } from '../../components/elements';
import { Box, Flex, Grid } from '../../components/layout';
import { Heading, Text } from '../../components/typography';
import { LocalStorageKeyEnum } from '../../localStorage/constants';
import theme from '../../theme';
import { Face } from '../../types/types';

const placeHolder = require('../../assets/images/avatar.png');

const FacePage = () => {
  const { id } = useParams();
  const [face, setFace] = useState<Face | null | undefined>(null);
  const [image, setImage] = useState<string>(placeHolder);

  useEffect(() => {
    const allFaces: Face[] = JSON.parse(
      localStorage.getItem(LocalStorageKeyEnum.FACES) || ''
    );
    if (id && allFaces) {
      setFace(allFaces.find((face) => face.id === parseInt(id)));
    }
    fetch(`https://bf.imgix.net/faces/${id}.jpg?w=1200&auto=compress`).then(
      (result) => result.ok && setImage(result.url)
    );
  }, [id]);

  return (
    <Box
      width="100%"
      css={css`
        .truncated-text {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 95vw;
          height: 1.2em;
          white-space: nowrap;
        }
      `}
    >
      <Flex width="100%" justifyContent="center" maxHeight="70%">
        <Image
          source={image}
          alt="avatar"
          minWidth="100%"
          minHeight="100%"
          overrideCss={css`
            object-fit: cover;
          `}
        />
      </Flex>
      {face && (
        <>
          <Box p="15px">
            <Heading mt="10px">{`${face.name} ${face.surname}`}</Heading>
            <Grid
              gridTemplateColumns="1fr 7fr"
              width="80%"
              gridRowGap="5px"
              gridColumnGap="5px"
              mt="10px"
            >
              <FontAwesomeIcon
                color={theme.colors.primary}
                icon={faBriefcase}
                css={css`
                  align-self: center;
                `}
              />
              <Text m={0} p={0} className="truncated-text">
                {face.position}
              </Text>
              <FontAwesomeIcon
                color={theme.colors.primary}
                icon={faBuildingUser}
                css={css`
                  align-self: center;
                `}
              />
              <Text m={0} p={0} className="truncated-text">
                {face.department}
              </Text>
              <FontAwesomeIcon
                color={theme.colors.primary}
                icon={faLocationDot}
                css={css`
                  align-self: center;
                `}
              />
              <Text m={0} p={0} className="truncated-text">
                {face.location}
              </Text>
              <FontAwesomeIcon
                color={theme.colors.primary}
                icon={faPhone}
                css={css`
                  align-self: center;
                `}
              />
              <Link
                className="truncated-text"
                m={0}
                p={0}
                href={`tel:${face.cellNumber}`}
                color={theme.colors.primary}
              >
                {face.cellNumber}
              </Link>
              <FontAwesomeIcon
                color={theme.colors.primary}
                icon={faEnvelope}
                css={css`
                  align-self: center;
                `}
              />
              <Link
                className="truncated-text"
                m={0}
                p={0}
                href={`mailto:${face.email}`}
                color={theme.colors.primary}
              >
                {face.email}
              </Link>
            </Grid>
            {face.reportsTo && (
              <Text className="truncated-text">{`Directly reports to ${face.reportsTo}`}</Text>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default FacePage;
