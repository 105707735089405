/** @jsxImportSource @emotion/react */
import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';
import ODOFacesApp from './components/app';

const App = () => {
  return (
    <div className="App body">
      <GoogleOAuthProvider clientId="570338979594-i4d7lk1in1su88n1u56eju9dumeucl9k.apps.googleusercontent.com">
        <ODOFacesApp />
      </GoogleOAuthProvider>
    </div>
  );
};

export default App;
