/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import theme from '../../theme';
import StandardCSS from '../../types/standard-css';
import StandardProps from '../../types/standard-props';

enum ButtonVariantEnum {
  BASIC = 'basic',
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

type ButtonVariants = 'basic' | 'filled' | 'outlined';

const Button = ({
  variant = ButtonVariantEnum.BASIC,
  onClick,
  children,
  overrideCss,
  ref,
  ...standardProps
}: {
  variant?: ButtonVariants;
  onClick?: () => void;
  children?: React.ReactNode | string;
  overrideCss?: SerializedStyles;
  ref?: any;
} & StandardProps) => (
  <button
    className={standardProps.className}
    ref={ref}
    onClick={onClick}
    css={css`
      ${StandardCSS(standardProps)}
      border: none;
      background-color: none;
      background: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      cursor: pointer;

      ${variant === ButtonVariantEnum.FILLED &&
      css`
        background-color: ${theme.colors.primary};
        color: ${theme.colors.mainBackground};
      `}
      ${variant === ButtonVariantEnum.OUTLINED &&
      css`
        border: 1px solid ${theme.colors.primary};
        border-radius: 22px;
      `}

      ${overrideCss}
    `}
  >
    {children}
  </button>
);

export default Button;
