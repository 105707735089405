import { GoogleCredentialResponse, GoogleLogin } from '@react-oauth/google';
import { Box, Flex } from '../../components/layout';
import { Image } from '../../components/elements';
import { css } from '@emotion/react';
import { Text } from '../../components/typography';
import useGoogleLogin from '../../hooks/use-google-login';
import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import theme from '../../theme';
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
const ODOLogo = require('../../assets/images/ODOStaffLogo.png');

const cookies = new Cookies();

const LoginPage = () => {
  const navigate = useNavigate();
  const [googleResponse, setGoogleResponse] =
    useState<GoogleCredentialResponse | null>(null);

  const handleGoogleLogin = (response: GoogleCredentialResponse) => {
    setGoogleResponse(response);
  };
  const { loading, authenticationResponse, error, authenticateWithGoogleJWT } =
    useGoogleLogin();

  useEffect(() => {
    cookies.remove('token');
    cookies.remove('user');
    cookies.remove('loginTime');
  }, []);

  useEffect(() => {
    if (googleResponse && googleResponse.credential) {
      authenticateWithGoogleJWT(googleResponse.credential);
    }
  }, [googleResponse]);

  useEffect(() => {
    if (authenticationResponse) {
      cookies.set('token', authenticationResponse.token);
      cookies.set(
        'user',
        JSON.stringify({
          email: authenticationResponse.googleResponse.email,
          name: authenticationResponse.googleResponse.given_name,
          surname: authenticationResponse.googleResponse.family_name,
          picture: authenticationResponse.googleResponse.picture,
        })
      );
      cookies.set('loginTime', new Date().getTime());
      navigate('/');
    }
  }, [authenticationResponse, navigate]);

  return (
    <Flex
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Image
        source={ODOLogo}
        mt="60px"
        my="10px"
        alt="ODO Staff Logo"
        width="60%"
        height="auto"
        mb="10px"
        //TODO: Add object-fit prop to Image component
        overrideCss={css`
          object-fit: contain;
        `}
      />
      <Text mb="20%" width="60%" textAlign="center">
        Please sign in using your OneDayOnly email
      </Text>
      <Box mb="30%">
        {!googleResponse && (
          <GoogleLogin onSuccess={handleGoogleLogin} size="large" useOneTap />
        )}
        {loading && (
          <Oval
            height={80}
            width={80}
            color={theme.colors.primary}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="rgba(64, 145, 203, 0.5)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        )}
      </Box>
    </Flex>
  );
};

export default LoginPage;
