import { Box, Flex } from "../layout";
import { Button } from "../elements";
import {
  faBuildingUser,
  faLocationDot,
  faMagnifyingGlass,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@emotion/react";
import theme from "../../theme";
import { Link } from "react-router-dom";
import { NavigationLinkEnum } from "../../types/routing";
import { useLocation } from "react-router-dom";

const NavButton = ({
  icon,
  link,
  isActive,
}: {
  icon?: any;
  link: string;
  isActive: boolean;
}) => {
  return (
    <Link to={link}>
      {isActive && (
        <Box
          width="100%"
          height="3px"
          mt="-10px"
          backgroundColor={theme.colors.primary}
          borderRadius={theme.borderRadius.medium}
        />
      )}
      <Button variant="basic" p="10px" mb="0" ml="0" width="auto">
        <Box
          overrideCss={css`
            @media ${theme.mediaQueries.tabletUp} {
              display: none;
            }
          `}
        >
          <Box px="15px">
            <FontAwesomeIcon
              icon={icon}
              fontSize="2em"
              color={theme.colors.primary}
            />
          </Box>
        </Box>
      </Button>
    </Link>
  );
};

const MobileNavBar = () => {
  const location = useLocation();
  const hideNavBar = location.pathname === NavigationLinkEnum.HOME;
  return (
    <Box
      backgroundColor="white"
      width="100%"
      borderT={`1px solid ${theme.colors.borderGrey}`}
      overrideCss={css`
        transition: all 1s ease-in;
        /* ${hideNavBar
          ? css`
              margin-bottom: -500px;
            `
          : css`
              margin-bottom: 0px;
            `} */
      `}
    >
      <Flex
        flexDirection="row"
        width="content-fit"
        justifyContent="space-evenly"
        pb="10px"
        pt="10px"
        ml="10px"
      >
        <NavButton
          isActive={location.pathname === `/${NavigationLinkEnum.ALL_FACES}`}
          link={NavigationLinkEnum.ALL_FACES}
          icon={faUsers}
        />
        <NavButton
          isActive={location.pathname === `/${NavigationLinkEnum.DEPARTMENTS}`}
          link={NavigationLinkEnum.DEPARTMENTS}
          icon={faBuildingUser}
        />
        <NavButton
          isActive={location.pathname === `/${NavigationLinkEnum.LOCATIONS}`}
          link={NavigationLinkEnum.LOCATIONS}
          icon={faLocationDot}
        />
        <NavButton
          isActive={location.pathname === `/${NavigationLinkEnum.SEARCH}`}
          link={NavigationLinkEnum.SEARCH}
          icon={faMagnifyingGlass}
        />
      </Flex>
    </Box>
  );
};

export default MobileNavBar;
