/** @jsxImportSource @emotion/react */
import theme from '../../theme';
import { Box, Flex } from '../layout';
import MobileNavBar from '../mobile-nav-bar';
import {
  Location,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { NavigationLinkEnum } from '../../types/routing';
import LandingPage from '../../pages/landing';
import AllFaces from '../../pages/all-faces';
import Search from '../../pages/search';
import { css } from '@emotion/react';
import { Text } from '../typography';
import Departments from '../../pages/departments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Locations from '../../pages/locations';
import { useEffect } from 'react';
import { Button } from '../elements';
import FacePage from '../../pages/face-page';
import useGetFaces from '../../hooks/use-get-faces';
import LoginPage from '../../pages/login';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const NavHeader = ({ location }: { location: Location }) => {
  const navigate = useNavigate();
  const path = location.pathname;

  const handleOnClick = () => {
    if (location.pathname.includes('person')) {
      navigate(-1);
    } else {
      navigate(NavigationLinkEnum.HOME);
    }
  };
  const title = path.replace('/', '').replace('-', ' ');
  return (
    <Box width="100vw" backgroundColor="white">
      <Button
        onClick={handleOnClick}
        pl="10px"
        pr="15px"
        textDecoration="none"
        my="10px"
        pb={0}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          size="2x"
          color={theme.colors.primary}
        />
      </Button>
      {!location.pathname.includes('person') && (
        <Text
          my="1%"
          p="0"
          color={theme.colors.primary}
          ml="10px"
          fontSize="20px"
        >
          {title}
        </Text>
      )}
    </Box>
  );
};

const ODOFacesApp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  //Check if user is authenticated
  useEffect(() => {
    const now = new Date();
    const lastLogin = cookies.get('loginTime');
    const hoursSinceLastLogin = lastLogin && (now.getTime() - lastLogin) / 36e5;
    const authToken = cookies.get('token');
    if (
      (!authToken || (lastLogin && hoursSinceLastLogin > 0.9)) &&
      !location.pathname.includes('/auth')
    ) {
      cookies.remove('token');
      cookies.remove('user');
      cookies.remove('loginTime');
      navigate('/auth');
    }
  }, [location.pathname, navigate]);

  const {
    loading: facesLoading,
    error: facesError,
    usingCache,
  } = useGetFaces();

  return (
    <Flex
      flexDirection="column"
      backgroundColor="white"
      overflowX="hidden"
      overflowY="hidden"
      height="-webkit-fill-available"
      width="100vw"
      overrideCss={css`
        ::-webkit-scrollbar {
          display: none;
        }
        @media ${theme.mediaQueries.tabletUp} {
          display: none;
        }
      `}
    >
      <Box
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            display: none;
          }
        `}
      >
        <Text>{'Please view this webpage on mobile :)'}</Text>
      </Box>
      {!(
        location.pathname === NavigationLinkEnum.HOME ||
        location.pathname.includes('/auth')
      ) && <NavHeader location={location} />}

      <Flex overflowY="scroll" height="100%" flex={1}>
        <Routes>
          <Route
            path={NavigationLinkEnum.HOME}
            element={
              <LandingPage
                facesError={facesError}
                facesLoading={facesLoading}
                usingCache={usingCache}
              />
            }
          />
          <Route path="/auth" element={<LoginPage />} />
          <Route
            path={`/${NavigationLinkEnum.ALL_FACES}`}
            element={<AllFaces />}
          />
          <Route
            path={`/${NavigationLinkEnum.DEPARTMENTS}`}
            element={<Departments />}
          />
          <Route
            path={`/${NavigationLinkEnum.LOCATIONS}`}
            element={<Locations />}
          />
          <Route path={`/${NavigationLinkEnum.SEARCH}`} element={<Search />} />
          <Route
            path={`/${NavigationLinkEnum.PERSON}`}
            element={<FacePage />}
          />
        </Routes>
      </Flex>
      {!location.pathname.includes('auth') && <MobileNavBar />}
    </Flex>
  );
};

export default ODOFacesApp;
