import { useEffect, useState } from 'react';
import FaceGrid from '../../components/face-grid';
import { Flex } from '../../components/layout';
import { LocalStorageKeyEnum } from '../../localStorage/constants';
import { Face } from '../../types/types';

const AllFaces = () => {
  const [faces, setFaces] = useState<Face[] | null>(null);

  useEffect(() => {
    const stringData = localStorage.getItem(LocalStorageKeyEnum.FACES);
    stringData && setFaces(JSON.parse(stringData));
  }, []);

  return (
    <Flex
      backgroundColor="white"
      width="100vw"
      height="100%"
      justifyContent="center"
      overflowY="scroll"
    >
      <FaceGrid faces={faces} />
    </Flex>
  );
};

export default AllFaces;
