import { Flex } from '../../components/layout';
import { Button, Image } from '../../components/elements';
import { css } from '@emotion/react';
import LinkButton from '../../components/link-button';
import { NavigationLinkEnum } from '../../types/routing';
import {
  faUsers,
  faBuildingUser,
  faLocationDot,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { Cookies } from 'react-cookie';

import { Text } from '../../components/typography';
import { CurrentUser } from '../../types/types';
import theme from '../../theme';
import { useState } from 'react';
const cookies = new Cookies();

const ODOLogo = require('../../assets/images/ODOStaffLogo.png');

const LandingPage = ({
  facesLoading,
  facesError,
  usingCache,
}: {
  facesLoading: boolean;
  facesError: any;
  usingCache: boolean;
}) => {
  const user = cookies.get('user') as CurrentUser;
  const [showSignOut, setShowSignOut] = useState(false);
  const handleSignOut = () => {
    cookies.remove('token');
    cookies.remove('user');
    cookies.remove('loginTime');
    window.location.reload();
  };
  return (
    <Flex
      width="100%"
      height="100%"
      backgroundColor="white"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {user && (
        <>
          <Button onClick={() => setShowSignOut(!showSignOut)}>
            <Flex
              position="absolute"
              top="5px"
              right="20px"
              alignItems="center"
            >
              <Text color={theme.colors.lightGrey}>
                {user.name} {user.surname}
              </Text>
              <Image
                ml="8px"
                source={user.picture}
                alt="usr"
                width="30px"
                height="30px"
                borderRadius="50%"
              />
            </Flex>
          </Button>
          {showSignOut && (
            <Button
              onClick={handleSignOut}
              position="absolute"
              top="45px"
              right="10px"
              backgroundColor="white"
              boxShadow="0 0 2px 2px rgba(0,0,0,0.05)"
              p="8px"
              px="15px"
              fontSize="12px"
            >
              Sign out
            </Button>
          )}
        </>
      )}
      <Image
        source={ODOLogo}
        mt="80px"
        my="10px"
        alt="ODO Staff Logo"
        width="60%"
        height="auto"
        //TODO: Add object-fit prop to Image component
        overrideCss={css`
          object-fit: contain;
        `}
      />
      {!facesLoading || usingCache ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          mt="20%"
          pb="10px"
          px="15%"
          pt="10px"
        >
          <LinkButton
            label="All Faces"
            link={NavigationLinkEnum.ALL_FACES}
            icon={faUsers}
          />
          <LinkButton
            label="Departments"
            link={NavigationLinkEnum.DEPARTMENTS}
            icon={faBuildingUser}
          />
          <LinkButton
            label="Locations"
            link={NavigationLinkEnum.LOCATIONS}
            icon={faLocationDot}
          />
          <LinkButton
            label="Search"
            link={NavigationLinkEnum.SEARCH}
            icon={faMagnifyingGlass}
          />
        </Flex>
      ) : facesLoading ? (
        <Text>Fetching fresh data...</Text>
      ) : (
        <Text>Something went wrong, please refresh</Text>
      )}
    </Flex>
  );
};

export default LandingPage;
