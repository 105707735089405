/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  faBriefcase,
  faBuildingUser,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/elements';
import { Flex, Grid } from '../../components/layout';
import { Text } from '../../components/typography';
import useIsOnScreen from '../../hooks/use-is-on-screen';
import theme from '../../theme';
import { Face } from '../../types/types';
import { MenuItemType } from '../face-menu-item';

const placeHolder = require('../../assets/images/avatar.png');

export const FaceCard = ({
  person,
  type,
}: {
  person: Face;
  type: MenuItemType;
}) => {
  const navigate = useNavigate();
  const [image, setImage] = useState<string>(placeHolder);
  const ref = useRef(null);
  const isOnScreen = useIsOnScreen(ref);

  useEffect(() => {
    fetch(
      `https://bf.imgix.net/faces/${person.id}.jpg?w=400&auto=compress`
    ).then((result) => result.ok && setImage(result.url));
  }, [person.id]);

  return (
    <div
      ref={ref}
      css={css`
        height: 200px;
        .truncated-text {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 25vw;
          height: 1.2em;
          white-space: nowrap;
        }
      `}
    >
      <Button
        className="face-card"
        height="200px"
        width="100%"
        onClick={() => navigate(`/person/${person.id}`)}
        variant="basic"
        p={0}
        m={0}
        overrideCss={css`
          text-decoration: none;
          color: black;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: none;
          ${isOnScreen &&
          css`
            background-image: url(${image});
            background-position-x: center;
          `}
        `}
      >
        <Flex
          height="100%"
          pl="10px"
          overrideCss={css`
            //align-content: flex-end;
            background: linear-gradient(
              0deg,
              rgba(20, 20, 20, 1) 0%,
              rgba(255, 255, 255, 0) 70%
            );
          `}
        >
          <Flex width="100%" height="100%" flexDirection="column">
            <Text
              fontSize="12px"
              mt="auto"
              m="0px"
              textAlign="left"
              color="white"
              className="truncated-text"
            >
              {person.name} {person.surname}
            </Text>
            <Flex alignItems="center" mt="3px">
              <FontAwesomeIcon
                icon={faBriefcase}
                color={theme.colors.primary}
                css={css`
                  height: 9px;
                `}
              />
              <Text
                fontSize="11px"
                m="0px"
                color="white"
                textAlign="left"
                ml="3px"
                textOverflow="ellipsis"
                className="truncated-text"
              >
                {person.position}
              </Text>
            </Flex>
            <Flex alignItems="center" mt="3px" mb="10px">
              <FontAwesomeIcon
                icon={
                  type === MenuItemType.LOCATION
                    ? faBuildingUser
                    : faLocationDot
                }
                color={theme.colors.primary}
                css={css`
                  height: 9px;
                `}
              />
              <Text
                fontSize="11px"
                m="0px"
                color="white"
                textAlign="left"
                ml="3px"
                className="truncated-text"
              >
                {type === MenuItemType.LOCATION
                  ? person.department
                  : person.location}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Button>
    </div>
  );
};

const FaceGrid = ({ faces }: { faces?: Face[] | null }) => (
  <Grid
    width="100%"
    height="100%"
    gridTemplateColumns="1fr 1fr 1fr"
    gridTemplateRows="repeat(auto-fill, 200px)"
    gridColumnGap="1px"
    gridRowGap="1px"
    overrideCss={css`
      ::-webkit-scrollbar {
        display: none;
      }
    `}
  >
    {!!faces &&
      faces.length &&
      faces.map((person: Face) => (
        <FaceCard
          person={person}
          key={person.id}
          type={MenuItemType.DEPARTMENT}
        />
      ))}
  </Grid>
);

export default FaceGrid;
