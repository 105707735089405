import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import theme from "../../theme";
import { Button } from "../elements";
import { Grid } from "../layout";
import { Text } from "../typography";

const LinkButton = ({
  label,
  icon,
  link,
}: {
  label: string;
  icon?: any;
  link: string;
}) => {
  return (
    <Link to={link}>
      <Button
        variant="outlined"
        p="10px"
        mb="10px"
        mr="10px"
        ml="0"
        width="100%"
        borderRadius={theme.borderRadius.medium}
        overrideCss={css`
          transition: all 0.1s ease !important;

          &:hover {
            background-color: rgba(64, 145, 203, 0.1);
          }
        `}
      >
        <Grid
          gridTemplateColumns="1fr 4fr"
          mx="10px"
          gridColumnGap="5px"
          alignItems="center"
        >
          <FontAwesomeIcon
            icon={icon}
            fontSize="1.3em"
            color={theme.colors.primary}
          />

          <Text
            fontSize="1.3em"
            textAlign="left"
            p={0}
            m={0}
            fontWeight="normal"
            color={theme.colors.darkGrey}
          >
            {label}
          </Text>
        </Grid>
      </Button>
    </Link>
  );
};

export default LinkButton;
