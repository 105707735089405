/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import theme from '../../theme';
import { Face } from '../../types/types';
import { Button } from '../elements';
import FaceGrid from '../face-grid';
import { Flex } from '../layout';
import { Text } from '../typography';

export enum MenuItemType {
  DEPARTMENT = 'department',
  LOCATION = 'location',
}

const FaceMenuItem = ({
  faces,
  title,
  lastChild,
  type,
}: {
  faces?: Face[] | null;
  title: string;
  lastChild: boolean;
  type: MenuItemType;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Button width="100%" onClick={() => setIsExpanded(!isExpanded)}>
      <Flex
        p="6px"
        borderB={!isExpanded ? `1px solid ${theme.colors.borderGrey}` : ''}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          fontSize="14px"
          textDecoration={isExpanded ? 'underlined' : 'none'}
        >
          {title}
        </Text>
        <FontAwesomeIcon
          icon={faChevronRight}
          css={css`
            transition-property: transform;
            transition-duration: 0.5s;
            ${isExpanded
              ? css`
                  transform: rotate(90deg);
                `
              : css`
                  transform: rotate(0deg);
                `}
          `}
        />
      </Flex>
      {isExpanded && faces && <FaceGrid faces={faces} />}
    </Button>
  );
};

export default FaceMenuItem;
