import { useEffect, useState } from 'react';
import FaceMenuItem, { MenuItemType } from '../../components/face-menu-item';
import { Flex } from '../../components/layout';
import { LocalStorageKeyEnum } from '../../localStorage/constants';
import { Face } from '../../types/types';
import {
  sortFacesAlphabetically,
  sortStringsAlphabetically,
} from '../../util/functions';

const Departments = () => {
  const [faces, setFaces] = useState<Face[] | null>(null);

  useEffect(() => {
    const stringData = localStorage.getItem(LocalStorageKeyEnum.FACES);
    stringData && setFaces(JSON.parse(stringData));
  }, []);

  const departments = Array.from(
    new Set(faces?.map((item) => item.department))
  ).sort((a, b) => sortStringsAlphabetically(a, b));

  return (
    <Flex
      backgroundColor="white"
      width="100vw"
      height="100%"
      flexDirection="column"
    >
      {departments.map((department, idx) => (
        <FaceMenuItem
          title={department}
          key={idx}
          faces={faces
            ?.filter((face) => face.department === department)
            .sort((a, b) => sortFacesAlphabetically(a, b))}
          lastChild={idx === departments.length - 1}
          type={MenuItemType.DEPARTMENT}
        />
      ))}
    </Flex>
  );
};

export default Departments;
