/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import FaceGrid from '../../components/face-grid';
import { Box, Flex } from '../../components/layout';
import { LocalStorageKeyEnum } from '../../localStorage/constants';
import Theme from '../../theme';
import { Face } from '../../types/types';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  // const [results, setResults] = useState<Face[] | null>(null);

  const faces: Face[] = JSON.parse(
    localStorage.getItem(LocalStorageKeyEnum.FACES) || ''
  );

  const results =
    searchTerm &&
    searchTerm !== '' &&
    faces &&
    faces.filter((face) =>
      `${face.name} ${face.surname}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

  return (
    <Box backgroundColor="white" width="100vw" overflow="hidden">
      <Flex
        height="50px"
        width="100%"
        pb="10px"
        justifyContent="center"
        alignItems="center"
      >
        <input
          placeholder="Type to search..."
          onChange={(event) => setSearchTerm(event.target.value)}
          css={css`
            width: 90%;
            height: 30px;
            font-size: 16px;
            border: none;
            border-bottom: 1px solid ${Theme.colors.borderGrey};
            border-radius: 0;
          `}
        />
      </Flex>
      <Flex height="100%" flex="1" overflowY="scroll">
        {results && results?.length > 0 && <FaceGrid faces={results} />}
      </Flex>
    </Box>
  );
};

export default Search;
