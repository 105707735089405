import { useEffect, useState } from 'react';
import FaceMenuItem, { MenuItemType } from '../../components/face-menu-item';
import { Flex } from '../../components/layout';
import { LocalStorageKeyEnum } from '../../localStorage/constants';
import { Face } from '../../types/types';
import {
  sortFacesAlphabetically,
  sortStringsAlphabetically,
} from '../../util/functions';

const Locations = () => {
  const [faces, setFaces] = useState<Face[] | null>(null);

  useEffect(() => {
    const stringData = localStorage.getItem(LocalStorageKeyEnum.FACES);
    stringData && setFaces(JSON.parse(stringData));
  }, []);

  const Locations = Array.from(
    new Set(faces?.map((item) => item.location))
  ).sort((a, b) => sortStringsAlphabetically(a, b));

  return (
    <Flex
      backgroundColor="white"
      width="100vw"
      height="100%"
      flexDirection="column"
    >
      {Locations.map((location, idx) => (
        <FaceMenuItem
          title={location}
          key={idx}
          faces={faces
            ?.filter((face) => face.location === location)
            .sort((a, b) => sortFacesAlphabetically(a, b))}
          lastChild={idx === Locations.length - 1}
          type={MenuItemType.LOCATION}
        />
      ))}
    </Flex>
  );
};

export default Locations;
