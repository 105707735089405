import { useState } from 'react';
import { AuthorizationResponse, GoogleCredential } from '../types/types';
import jwt from 'jwt-decode';

const useGoogleLogin = () => {
  const [loading, setLoading] = useState(false);
  const [authenticationResponse, setAuthenticationResponse] =
    useState<AuthorizationResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const authenticateWithGoogleJWT = async (googleCredential: string) => {
    setLoading(true);
    const googleToken = googleCredential;
    const credentialDecoded: GoogleCredential = await jwt(googleCredential);
    const email = credentialDecoded.email;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/auth/login`,
        {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            email,
            googleToken,
          }),
        }
      );
      const data = await response.json();
      if (response.status !== 200) {
        setError(data.message);
      } else {
        setAuthenticationResponse(data);
        setError(null);
        setLoading(false);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, authenticationResponse, error, authenticateWithGoogleJWT };
};

export default useGoogleLogin;
